import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface IConfig {
  company: string;
  apiUrl: string;
  imageUrl: string;
  googleApiUrl: string;
  googleApiKey: string;
}

@Injectable()
export class ConfigService {
  config: IConfig = {
    company: '',
    apiUrl: '',
    imageUrl: '',
    googleApiUrl: '',
    googleApiKey: '',
  };

  private configUpdate = new Subject();
  configUpdate$ = this.configUpdate.asObservable();

  constructor(private http: HttpClient) {}

  async loadConfig(company: string): Promise<void> {
    const configPath = `/assets/config/${company}.json`;

    return this.http
      .get<IConfig>(configPath)
      .toPromise()
      .then((config) => {
        this.updateConfig(config);
      })
      .catch((err) => {
        console.error('Error loading config for', company, err);
      });
  }

  updateConfig(config: IConfig) {
    this.config = {
      ...this.config,
      ...config,
    };
    this.configUpdate.next(this.config);
  }

  getConfig() {
    return this.config;
  }
}
