import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { LayoutService } from 'angular-admin-lte';
import { ConfigService } from '../core/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './page.component.html',
})
export class PageComponent implements OnInit, AfterViewChecked {
  public customLayout!: boolean;

  logoSrc = 'assets/img/logo-header.png';

  constructor(private layoutService: LayoutService, private configService: ConfigService) {}

  ngOnInit(): void {
    this.layoutService.isCustomLayout.subscribe((value) => {
      this.customLayout = value;
    });
    const company = this.configService.getConfig().company;
    if (company === 'nxge') {
      this.logoSrc = 'assets/img/nx_logo.png';
    }
  }

  ngAfterViewChecked(): void {
    var iconToggle = document.getElementsByClassName('sidebar-right-toggle')[0];

    var sidebar = document.getElementsByTagName('control-sidebar')[0];
    var sidebarOpen = document.getElementsByTagName('control-sidebar-open')[0];

    if (sidebar?.classList.contains('control-sidebar-open')) {
      iconToggle.classList.add('open');
    }
  }
}
