import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private googleApiUrl: string;
  private googleApiKey: string;
  constructor(private http: HttpClient, private configService: ConfigService) {
    const { googleApiUrl, googleApiKey } = this.configService.getConfig();
    this.googleApiKey = googleApiKey;
    this.googleApiUrl = googleApiUrl;
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.googleApiUrl}${path}`).pipe(catchError(this.formatErrors));
  }

  getAddressInfo(gps: string): Observable<any> {
    return this.http
      .get(`${this.googleApiUrl}maps/api/geocode/json?latlng=${gps}&key=${this.googleApiKey}&language=th`)
      .pipe(catchError(this.formatErrors));
  }
}
