import { Injectable } from '@angular/core';
import moment from 'moment';
import { OrderRecheckGiftModel, OrderRecheckModel, OrderRecheckProductModel } from 'src/app/models/order-recheck.model';
import { OrderCommonModel } from 'src/app/models/order.model';
import { CommonHelper } from 'src/app/utils/commonHelper';
import { CommonService } from './common.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class OrderRecheckService {
  orderCommon: OrderCommonModel;
  imageUrl: string;
  constructor(private commonService: CommonService, private configService: ConfigService) {
    this.imageUrl = this.configService.getConfig().imageUrl;
  }

  public async bindOrderRecheck(model: any) {
    this.orderCommon = await this.commonService.getOrderCommon();
    const promotion = this.orderCommon.promotion.find((x) => x.id === model.promotionId);
    const orderStatus = this.orderCommon.orderStatus.find((x) => x.id === model.orderStatus);
    const transportation = this.orderCommon.transportationTypes.find((x) => x.id === model.transportationType);
    const province = this.orderCommon.provinces.find((x) => x.provinceID === model.provinceId);
    const provinceName = province ? province.name : '';
    const combinedAddress = `${model.address} ${model.subDistrict} ${model.district} ${provinceName} ${model.zipCode}`;
    const productList = model.products?.map((x: any) => {
      const obj: OrderRecheckProductModel = {
        id: x.productId,
        name: x.nameTH,
        quantity: x.quantity,
      };
      return obj;
    });

    const giftList = model.customerOrderGiftItem?.map((x: any) => {
      const obj: OrderRecheckGiftModel = {
        id: x.giftItemId,
        name: this.orderCommon.giftItem.find((y) => y.id === x.giftItemId)?.name,
        amount: x.amount,
      };
      return obj;
    });
    const referenceImageList = model.referenceInformation?.map((e: any) => {
      return {
        id: e.id,
        fileReferenceTypeId: e.fileReferenceTypeId,
        referenceText: e.referenceText,
        filePath: CommonHelper.mapImageUrl(this.imageUrl, e.filePath),
        paymentGatewayId: this.orderCommon.paymentGateway.find((a) => a.id === e.paymentGatewayId)?.name,
      };
    });

    const sortReferenceImage = referenceImageList?.sort(
      (a: any, b: any) => a.fileReferenceTypeId - b.fileReferenceTypeId
    );
    const totalProductQuantity = model.products?.reduce((a: number, b: any) => a + b.quantity, 0);
    const obj: OrderRecheckModel = {
      orderId: model.id,
      invoiceNumber: model.invoiceNumber,
      orderPrice: model.orderPrice,
      deliveryPrice: model.deliveryPrice,
      discountPrice: model.discountPrice,
      totalPrice: model.totalPrice,
      quantity: totalProductQuantity,
      boxQuantity: CommonHelper.boxCountGroup(model.boxCode),
      hasImage: sortReferenceImage?.length > 0 ? true : false,
      isCheck: model.isCheck,
      isWaitingData: model.isWaitingData,
      deliveryRemark: model.deliveryRemark,
      smsRemark: model.smsRemark,
      factoryRemark: model.factoryRemark,
      referenceText: model.referenceText,
      referenceNumber: model.referenceNumber,
      waitingData: model.waitingData,
      referenceInformation: sortReferenceImage,
      nextOrderId: model.nextOrderId,
      previousOrderId: model.previousOrderId,
      place: {
        name: '',
        setName: promotion ? promotion.name : '',
        setQuantity: model.quantity,
        boxNumber: model.boxQuantity,
        remark: '',
        tel: model.deliveryPhoneNumber,
        secondPhone: model.secondPhone,
        province: provinceName,
        transport: transportation ? transportation.name : '',
        address: combinedAddress,
        deliveryRemark: model.deliveryRemark,
        products: productList,
        gifts: giftList,
        deliveryDate: moment(model.deliveryDate).format('DD/MM/YYYY'),
        receiverName: model.receiverName,
        status: orderStatus ? orderStatus.name : '',
      },
    };
    return obj;
  }
}
