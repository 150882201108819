import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  OrderCustomerLineItem,
  OrderDeliveryDetail,
  OrderFileReference,
  OrderItemModel,
  OrderPromotion,
} from 'src/app/models/api-order.model';
import {
  OrderRecheckModel,
  OrderRecheckPlaceModel,
  OrderRecheckProductModel,
} from 'src/app/models/order-recheck.model';
import {
  OrderDetail,
  OrderDetailImageFileModel,
  OrderPlaceModel,
  OrderProductItemModel,
} from 'src/app/models/order.model';
import { ConfigService } from './config.service';
import { InformationMessageService } from './information-message.service';
import { OrderService } from './order.service';
@Injectable({
  providedIn: 'root',
})
export class SaveOrderService {
  private orderSouce = new BehaviorSubject(new OrderDetail());
  public selectedPlaceIndex = 0;

  orderDetail = this.orderSouce.asObservable();
  orderDetailModel: OrderDetail;
  imageUrl: string;

  constructor(
    private orederService: OrderService,
    private messageService: InformationMessageService,
    private configService: ConfigService
  ) {
    this.imageUrl = this.configService.getConfig().imageUrl;
  }

  createNewOrderDetail() {
    const model = new OrderDetail();
    model.ref_id = null;
    model.customerId = null;
    model.orderDate = moment().toDate();
    model.isCheck = false;
    const place = new OrderPlaceModel();
    place.deliveryDate = moment().add(1, 'd').toDate();
    place.customerAddressId = null;
    place.products = [];
    model.places = place;
    this.updateOrderDetail(model);
  }

  updateOrderDetail(model: OrderDetail) {
    this.orderSouce.next(model);
    this.validateSaveable(model);
  }

  async saveOrder() {
    const apiModel = await this.convertToAPIModel();
    this.orederService
      .createOrder(apiModel)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ExceptionMethod) {
          this.messageService.showError(res.ExceptionMethod);
        } else {
          this.messageService.showSuccess('Update successful');
          this.bindOrderDetail(res);
        }
      });
  }

  async updateOrder() {
    const apiModel = await this.convertToAPIModel();
    this.orederService
      .updateOrder(+apiModel.id, apiModel)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.ExceptionMethod) {
          this.messageService.showError(res.ExceptionMethod);
        } else {
          this.messageService.showSuccess('Update successful');
          this.bindOrderDetail(res);
        }
      });
  }

  getProductQuantity(products: any[]) {
    let quantity = 0;

    products.forEach((prod: any) => {
      quantity += prod.quantity;
    });
    return quantity;
  }

  validateSaveable(model: OrderDetail) {
    if (model.customerId == undefined) {
      model.isSaveable = false;
      return;
    }

    if (model.isCheck) {
      // console.log('product count', this.getProductQuantity(model.places[0]?.products));
      // console.log('customerId', model.customerId)
      // console.log('tel', model.tel)
      // console.log('ref_id', model.ref_id)
      // console.log('receiverName', model.places[0].receiverName)
      // console.log('customerAddressId', model.places[0].customerAddressId)
      // console.log('address', model.places[0].address)
      // console.log('tel', model.places[0].tel)
      // console.log('transport', model.places[0].transport)

      if (
        model.numberOfSet > 0 &&
        model.numberOfSet == this.getProductQuantity(model.places?.products) &&
        model.customerId != undefined &&
        model.tel != '' &&
        model.ref_id != undefined &&
        model.places.receiverName != '' &&
        model.places.customerAddressId != undefined &&
        model.places.address != '' &&
        model.places.tel != '' &&
        model.places.transport != undefined
      ) {
        model.isSaveable = true;
      } else {
        model.isSaveable = false;
      }
    } else {
      model.isSaveable = true;
    }
  }

  async bindOrderDetail(response: any) {
    try {
      const model = new OrderDetail();
      model.id = response.id;
      model.orderDate = moment(response.orderDate).toDate();
      model.customerId = response.customerId;
      model.invoiceNumber = response.invoice?.invoiceNumber;
      model.ref_id = response.referenceId;
      //model.tel = response.;
      model.paymentType = response.paymentType;
      model.isCheck = response.isDataComplete;
      //model.numberOfSet = response.;
      model.selectedPlaceIndex = 0;
      model.referenceText = response.referenceText;
      // model.refImageFile = response.;
      // model.profImageFile = response.;
      // model.moreSlipImageFile = response.;
      model.paymentStatus = response.paymentStatusId;

      if (response.fileReference && response.fileReference.length > 0) {
        const refFile = response.fileReference.find((x: any) => x.fileReferenceTypeId == 1);
        const profFile = response.fileReference.find((x: any) => x.fileReferenceTypeId == 2);
        const moreFile = response.fileReference.find((x: any) => x.fileReferenceTypeId == 3);

        if (refFile) {
          const refModel = new OrderDetailImageFileModel();
          refModel.id = refFile.id;
          refModel.filePath = `${this.imageUrl}${refFile.filePath}`;
          model.refImageFile = refModel;
        }

        if (profFile) {
          const profFileModel = new OrderDetailImageFileModel();
          profFileModel.id = profFile.id;
          profFileModel.filePath = `${this.imageUrl}${profFile.filePath}`;
          model.profImageFile = profFileModel;
        }

        if (moreFile) {
          const moreFileModel = new OrderDetailImageFileModel();
          moreFileModel.id = moreFile.id;
          moreFileModel.filePath = `${this.imageUrl}${moreFile.filePath}`;
          model.refImageFile = moreFileModel;
        }
      }

      model.isSaveable = true;
      model.communicationMethodId = response.communicationMethodId;
      const delivery = response.deliveryDetail[0];
      const placeModel = new OrderPlaceModel();
      model.orderAddressDetailId = delivery.id;
      placeModel.remark = response.remark;
      placeModel.placeId = delivery.routeId;
      placeModel.deliveryNumber = '0';
      placeModel.deliveryDate = moment(response.deliveryDate).toDate();
      placeModel.setId = delivery.customerOrderPromotion[0]?.promotionId;
      model.numberOfSet = delivery.customerOrderPromotion[0]?.quantity;
      placeModel.setQuantity = delivery.customerOrderPromotion[0]?.quantity;
      placeModel.boxNumber = delivery.boxCount;
      placeModel.receiverName = delivery.recieverName;
      placeModel.customerAddressId = delivery.customerAddressId;
      placeModel.address = delivery.customerAddress.deliveryAddress;
      model.tel = delivery.customerAddress.phone;
      placeModel.tel = delivery.customerAddress.phone;
      placeModel.province = delivery.customerAddress.provinceId;
      // district: string | '';
      // zipcode: string | '';
      placeModel.locationLink = delivery.customerAddress.googleMapUrl;
      placeModel.transport = delivery.transportationTypeId;
      placeModel.orderPrice = response.orderPrice;
      placeModel.deliveryPrice = response.deliveryPrice;
      placeModel.totalPrice = response.totalPrice;

      placeModel.deliveryRemark = delivery.deliveryRemark;
      placeModel.status = response.orderStatusId;
      placeModel.timeRemark = delivery.timeRemark;
      placeModel.isUpcountry = delivery.isUpcountry;

      const productionList = Array();
      delivery.customerOrderLineItem.forEach((product: any) => {
        const modelProduct = new OrderProductItemModel();
        modelProduct.id = product.id;
        modelProduct.productId = product.product.id;
        modelProduct.productCode = product.product.ipCode;
        modelProduct.nameTh = product.product.nameTH;
        modelProduct.nameEn = product.product.nameEN;
        modelProduct.quantity = product.amount;
        productionList.push(modelProduct);
      });

      placeModel.products = productionList;
      model.places = placeModel;

      await this.updateOrderDetail(model);

      return model;
    } catch (e) {
      this.messageService.showError(e.message);
      return new OrderDetail();
    }
  }

  async convertToAPIModel() {
    await this.orderDetail.subscribe((o) => (this.orderDetailModel = o));

    const apiModel = new OrderItemModel();
    const detail = this.orderDetailModel;
    const place = this.orderDetailModel.places;
    apiModel.id = detail.id;
    apiModel.customerId = detail.customerId;
    apiModel.invoiceId = detail.invoiceNumber;
    // apiModel.communicationMethodId: number | undefined;
    apiModel.orderDate = moment(detail.orderDate).format();
    apiModel.deliveryDate = moment(place.deliveryDate).format();
    apiModel.remark = place.remark;
    apiModel.orderStatusId = place.status;
    apiModel.referenceText = detail.referenceText;
    apiModel.paymentStatusId = detail.paymentStatus;
    apiModel.distributorId = 1;
    apiModel.isDataComplete = detail.isCheck;
    apiModel.paymentType = detail.paymentType;
    apiModel.referenceId = detail.ref_id;

    const deliveryModel = new OrderDeliveryDetail();
    deliveryModel.id = detail.orderAddressDetailId;
    deliveryModel.routeId = place.placeId;
    deliveryModel.customerAddressId = place.customerAddressId;
    deliveryModel.DeliveryRemark = place.deliveryRemark;
    //deliveryModel.deliveryStatusId = place.
    // deliveryModel.distributorId: string | number | 0;
    deliveryModel.recieverName = place.receiverName;
    // imagePath: string | '';
    // recievedBy: string | '';
    // recievedTime: string | Date | '';
    deliveryModel.boxCount = place.boxNumber;
    deliveryModel.isUpcountry = place.isUpcountry;
    deliveryModel.orderPrice = place.orderPrice;
    deliveryModel.deliveryPrice = place.deliveryPrice;
    deliveryModel.totalPrice = place.totalPrice;
    deliveryModel.transportationTypeId = place.transport;
    deliveryModel.timeRemark = place.timeRemark;

    const lineItems: OrderCustomerLineItem[] = Array();
    const products = place.products;
    products.forEach(function (prod: any) {
      const newProd = new OrderCustomerLineItem();
      newProd.id = prod.id;
      newProd.productId = prod.productId;
      newProd.amount = prod.quantity;
      newProd.customerAddressId = place.customerAddressId;

      lineItems.push(newProd);
    });

    const promotions: OrderPromotion[] = Array();
    const promotion = new OrderPromotion();
    promotion.promotionId = place.setId;
    promotion.quantity = detail.numberOfSet;
    promotions.push(promotion);
    deliveryModel.customerOrderLineItem = lineItems;
    deliveryModel.customerOrderPromotion = promotions;

    apiModel.deliveryDetail = Array();
    apiModel.deliveryDetail.push(deliveryModel);
    apiModel.fileReference = Array();
    if (detail.refImageFile != undefined) {
      const refImage = new OrderFileReference();
      refImage.fileBase64String = detail.refImageFile.fileBase64String;
      refImage.fileReferenceTypeId = 1;
      apiModel.fileReference.push(refImage);
    }

    if (detail.profImageFile != undefined) {
      const profImage = new OrderFileReference();
      profImage.fileBase64String = detail.profImageFile.fileBase64String;
      profImage.fileReferenceTypeId = 2;
      apiModel.fileReference.push(profImage);
    }

    if (detail.moreSlipImageFile != undefined) {
      const slipImage = new OrderFileReference();
      slipImage.fileBase64String = detail.moreSlipImageFile.fileBase64String;
      slipImage.fileReferenceTypeId = 3;
      apiModel.fileReference.push(slipImage);
    }

    return apiModel;
  }

  async getRecheckModel(proviceList: any[], statusList: any[]) {
    await this.orderDetail.subscribe((o) => (this.orderDetailModel = o));
    const recheck = new OrderRecheckModel();
    const orderPlace = this.orderDetailModel.places;
    recheck.invoiceNumber = this.orderDetailModel.invoiceNumber;
    recheck.orderPrice = +orderPlace.orderPrice;
    recheck.discountPrice = 0;
    recheck.deliveryPrice = +orderPlace.deliveryPrice;
    recheck.totalPrice = +orderPlace.totalPrice;

    const place = new OrderRecheckPlaceModel();
    place.name = orderPlace.receiverName;
    place.setName = '';
    place.setQuantity = orderPlace.setQuantity;
    place.remark = orderPlace.remark;
    place.boxNumber = orderPlace.boxNumber;
    place.province = proviceList.find((x) => x.provinceID == orderPlace.province).name;
    place.transport = '';
    place.tel = orderPlace.tel;
    place.address = orderPlace.address;
    place.deliveryRemark = orderPlace.deliveryRemark;
    place.deliveryDate = moment(orderPlace.deliveryDate).format('DD/MM/YYYY');
    place.receiverName = orderPlace.receiverName;

    place.status = statusList.find((x) => x.id == orderPlace.status)?.name;

    const remarkProductions = new Array();
    orderPlace.products.forEach(function (prod: any) {
      const newProd = new OrderRecheckProductModel();
      newProd.id = prod.id;
      newProd.name = prod.nameTh;
      newProd.quantity = prod.quantity;

      remarkProductions.push(newProd);
    });

    place.products = remarkProductions;
    recheck.place = place;

    return recheck;
  }
}
